import React from 'react'
import { Row, Col } from 'react-bootstrap'
import Section from './Section'
import FeatureHandshakeImg from '../Resources/feature-handshake.png'
import FeatureHitchhikerImg from '../Resources/feature-hitchhiker.png'
import FeatureApprovalImg from '../Resources/feature-approval.png'

interface Props {
    
}

const Features = (props: Props) => {
    return (
        <Section bg='#BB9979'>
            <Row>
                <Col>
                    <h1 className="text-white display-2">Why Sick Freakin' Thumbs</h1>
                    <hr className="mb-5"/>
                </Col>
            </Row>
            <Row>
                <Col md="12" lg="4">
                    <div className="mb-5 sft-feature-bg">
                        <img className="img-fluid" src={FeatureHandshakeImg}/>
                    </div>
                    <h3>Handshaking</h3>
                    <p>
                        Shake hands with confidence knowing that your hands have the Sickest Freakin' Thumbs&nbsp;&trade;.
                        Never get caught off-guard in business meetings again.
                    </p>
                </Col>
                <Col md="12" lg="4">
                    <div className="mb-5 sft-feature-bg">
                        <img className="img-fluid" src={FeatureHitchhikerImg}/>
                    </div>
                    <h3>Hitch Any Ride</h3>
                    <p>
                        Have your pick of any car passing by. When the driver sees those golden thumbs, they'll
                        drive you from Alaska to Hawaii.
                    </p>
                </Col>
                <Col md="12" lg="4">
                    <div className="mb-5 sft-feature-bg">
                        <img className="img-fluid" src={FeatureApprovalImg}/>
                    </div>
                    <h3>Show Ultimate Approval</h3>
                    <p>
                        Best thing ever, or worst thing ever... Give a thumbs up (or down) and know that they will
                        receive the full impact of your approval (or complete and utter rejection).
                    </p>
                </Col>
            </Row>
        </Section>
    )
}

export default Features
