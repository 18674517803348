import React from 'react';
import { Container } from 'react-bootstrap';
import './App.css';
import Title from './Components/Title';
import Features from './Components/Features';
import Progress from './Components/Progress';
import InfoRequest from './Components/InfoRequest';
import Testimonials from './Components/Testimonials';

function App() {
  return (
    <>
      <Container fluid>
        {/* <Title /> */}
        <Features />
        <Testimonials />
        <Progress />
        <InfoRequest />
      </Container>
    </>
  );
}

export default App;
