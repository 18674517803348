import React, { useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import Section from './Section'
import { submitRequestAQuote } from '../Services/api';

interface Props {
    
}

const InfoRequest = (props: Props) => {
    const [ form, setForm ] = useState({
        name: '',
        email: '',
        tellUsWhy: ''
    });

    const [ showSuccessModal, setShowSuccessModal ] = useState(false);
    const handleCloseModal = () => setShowSuccessModal(false);

    const [ sending, setSending ] = useState(false);

    const handleOnChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        setForm({
            ...form,
            [evt.target.name]: evt.target.value
        })
    }
    
    const handleSubmit = async (evt: React.FormEvent) => {
        evt.preventDefault();
        setSending(true);
        setShowSuccessModal(true);
        await submitRequestAQuote(form);
        setSending(false);
    }

    return (
        <Section bg="#CF5535">
            <Row>
                <Col>
                    <h1 className="display-2 text-center">Why wait?</h1>
                    <p className="display-6 text-center">Turn your <span className="fst-italic">weak, embarrassing</span> thumbs into <span className="fw-bolder text-decoration-underline">✨ Sick Freakin' Thumbs ✨</span> today!</p>
                    <hr className="mb-5"/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="infoRequestName">
                            <Form.Label>Name (required)</Form.Label>
                            <Form.Control required name="name" value={form.name} onChange={handleOnChange}></Form.Control>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="infoRequestEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" name="email" value={form.email} onChange={handleOnChange}></Form.Control>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="infoRequestMessage">
                            <Form.Label>Let us know why You want Sick Freakin' Thumbs. 👍</Form.Label>
                            <Form.Control as="textarea" rows={3} name="tellUsWhy" value={form.tellUsWhy} onChange={handleOnChange}></Form.Control>
                        </Form.Group>
                        <Button type="submit" variant="info" size="lg">Request More Info</Button>
                    </Form>
                </Col>
            </Row>
            <Modal show={showSuccessModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Heck yeah, {form.name}.</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        {sending ? 'Sending...' : 'Sent!'}
                    </p>
                    <p>
                        Thanks for reaching out. For that, you get two Sick Freakin' Thumbs up 👍👍.
                    </p>
                </Modal.Body>
            </Modal>
        </Section>
    )
}

export default InfoRequest
