import axios from 'axios';

const apiBase = 'https://3qysfmq2nk.execute-api.us-west-2.amazonaws.com/default';

interface RequestAQuoteModel {
    name: string;
    email: string;
    tellUsWhy: string;
}

export const submitRequestAQuote = (req: RequestAQuoteModel) => axios.post(`${apiBase}/request-a-quote`, req);