import React from 'react'
import { Carousel, Col, Row } from 'react-bootstrap'
import Section from './Section'
import Testimonial from './Testimonial'
import TestimonialImage1 from '../Resources/testimonial-1.jpg'
import TestimonialImage2 from '../Resources/testimonial-2.jpg'
import TestimonialImageLazyMike from '../Resources/testimonial-lazy-mike.jpg'

interface Props {

}

const Testimonials = (props: Props) => {
    return (
        <Section bg='#27838A'>
            <Row>
                <Col>
                    <h1 className="display-2 text-end">Still not buying it?</h1>
                    <p className="display-6 text-end">Check out these real-life success stories.</p>
                    <hr className="mb-5"/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Carousel interval={10000} onSelect={() => {}}>
                        <Carousel.Item>
                            <Testimonial
                                name="Mr. Albert"
                                img={TestimonialImage1}
                                blurb="When my son Ethan was 3 years old, I decided to do the Itsy Bitsy Spider song. When I started making the motion using my fingers and thumbs, I could tell by the look in his eye that I had lost all of his respect. I was ashamed and embarrassed. Then I found Sick Freakin' Thumbs. With the help of the expert Thumbinator (love you Jerry!), I was able to connect with my child again in only 6 weeks! He even said he might be able to call me Dad again! Thanks SFT!"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <Testimonial
                                name="Low Siento"
                                img={TestimonialImage2}
                                blurb="When I was being investigated for a serious felony, the detective on the case asked for my fingerprints. They had the fingerprint reader on a flat surface in the middle of a desk. I tried and tried to have it read my thumbprint, but I just couldn't bend it like I needed to. They locked me up. The team at Sick Freakin' Thumbs was determined to help me out. My Thumbinator even visited me in prison to help me work through the program. Now I can give my thumbprint at a perfect 90&deg; angle, and my appeal will reach the courts soon. 🤘"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <Testimonial
                                name="Lazy Mike"
                                img={TestimonialImageLazyMike}
                                blurb={"Lifeguard Academy is not easy. Before Sick Freakin' Thumbs, I was conducting a field training exercise with my mentor Maggie Anne \"Shotgun\" Groves. There was a gentleman who looked like he was named Steven in the water on a very choppy day. The double-red flags were up. I pointed to him and made a motion with my thumb for him to get out of the water. He saw my thumbs and laughed, swimming even further out. He never made it back to shore that day. I was suspended from the Academy."}
                            />
                        </Carousel.Item>
                    </Carousel>
                </Col>
            </Row>
        </Section>
    )
}

export default Testimonials
