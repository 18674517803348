import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

interface Props {
    bg: string;
}

const Section : React.FunctionComponent<Props> = (props: React.PropsWithChildren<Props>) => {
    return (
        <Row className="sft-section pb-5" style={{ backgroundColor: props.bg }}>
            <Col>
                <Container className="mt-5">
                    {props.children}
                </Container>
            </Col>
        </Row>
    )
}

export default Section
