import React from 'react'

interface Props {
    name: string;
    img: string;
    blurb: string;
}

const Testimonial = (props: Props) => {
    return (
        <>
            <div className="text-center mb-5" style= {{marginLeft: "15%", marginRight: "15%"}}>
                <img className="img-fluid rounded mb-5 img-thumbnail" style={{maxWidth: '200px'}} src={props.img} />
                <blockquote className="blockquote sft-quote">
                    <p>{props.blurb}</p>
                    <footer className="blockquote-footer text-white fst-italic mt-2">{props.name}</footer>
                </blockquote>
            </div>
        </>
    )
}

export default Testimonial
